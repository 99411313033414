
import { defineComponent, ref } from 'vue'
import TmSearch from '@/components/shared/TmSearch.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'

export default defineComponent({
  components: {
    TmFormLine,
    TmSearch,
  },
  setup() {
    const search = ref('')

    return {
      search,
    }
  },
})
